import '../styles/cookies-dialog.scss';

import { Button, Drawer } from 'antd';
import { isNil } from 'lodash-es';
import React, { useEffect, useState } from 'react';

import { win } from '../fragments';
import { Col, Container, Row } from './layout';

const COOKIES_ID = 'cookies-mywayclinic.com';

const CookiesDialog: React.FC = () => {
  const [isDrawerOpened, setDrawerOpened] = useState<boolean>(false);

  useEffect(() => {
    const maybeCookies = win?.localStorage.getItem(COOKIES_ID);
    const cookies = isNil(maybeCookies) ? undefined : JSON.parse(maybeCookies);

    if (cookies !== true) {
      setDrawerOpened(true);
    }
  }, []);

  const handleCookiesAccept = () => {
    win?.localStorage.setItem(COOKIES_ID, JSON.stringify(true));
    setDrawerOpened(false);
  };

  return (
    <Drawer
      placement="bottom"
      closable={false}
      mask={false}
      height={125}
      visible={isDrawerOpened}
      className="cookies-dialog-drawer"
    >
      <Container>
        <Row>
          <Col>
            <p>
              Nasza strona internetowa wykorzystuje pliki cookie, aby zapewnić
              jej prawidłowe działanie. Korzystanie z aplikacji bez zmiany
              ustawień dotyczących cookies oznacza, że będą one przechowywane w
              pamięci urządzenia. Ustawienia te można zmienić w przeglądarce
              internetowej.
            </p>
          </Col>
          <Col span={{ sm: 24, md: 4 }}>
            <Button
              onClick={handleCookiesAccept}
              type="primary"
              block
              aria-label="Akceptuję"
            >
              Akceptuję
            </Button>
          </Col>
        </Row>
      </Container>
    </Drawer>
  );
};

export default CookiesDialog;
