import '../styles/blog-post-item.scss';

import { Button, Typography } from 'antd';
import { navigate } from 'gatsby';
import React from 'react';

import Image from '../components/image';
import { Box } from '../components/layout';
import { BlogPostPartial } from '../data';
import { usePageData } from '../data/hooks/use-page-data';
import { formatDate, resolveBlogPostPath } from '../utils';
import ArrowRightIcon from './icons/arrow-right';

export const BlogPostItemContent: React.FC<{ item: BlogPostPartial }> = ({
  item,
}) => {
  const pagesData = usePageData().list;

  return (
    <>
      <Box
        justify="space-between"
        align="middle"
        className="blog-post-item__time-box"
      >
        <Typography.Paragraph className="blog-post-item__subtitle">
          {item.subtitle}
        </Typography.Paragraph>
        <time>{formatDate(item.date)}</time>
      </Box>

      <Typography.Title level={4}>{item.title}</Typography.Title>

      <hr />

      <Typography.Paragraph ellipsis={{ rows: 2 }}>
        {item.description}
      </Typography.Paragraph>

      <Button
        className="enhanced-button"
        type="link"
        onClick={() => navigate(resolveBlogPostPath(pagesData, item))}
        aria-label="Czytaj dalej"
      >
        <Box align="middle">
          <span style={{ marginRight: '0.5rem' }}>Czytaj dalej</span>
          <ArrowRightIcon />
        </Box>
      </Button>
    </>
  );
};

const BlogPostItem: React.FC<{ item: BlogPostPartial }> = ({ item }) => {
  return (
    <Box
      className="blog-post-item"
      justify="center"
      direction="column"
      align="middle"
    >
      <Box className="blog-post-item__box">
        <Image id={item.image.id} />
        <Box>
          <BlogPostItemContent item={item} />
        </Box>
      </Box>
    </Box>
  );
};

export default BlogPostItem;
