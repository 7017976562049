import '../../styles/enhanced-link.scss';

import { Link } from 'gatsby';
import React from 'react';

import { NavigationConfig } from '../../data';
import { useLinkResolver } from '../../hooks';

interface EnhancedLinkProps {
  item: NavigationConfig;
}

const EnhancedLink: React.FC<EnhancedLinkProps> = ({ item }) => {
  const linkResolver = useLinkResolver();

  return (
    <Link
      activeClassName="active"
      className="link item"
      aria-label={item.title}
      partiallyActive
      key={item.link}
      to={linkResolver(item.link)}
    >
      {item.title}
    </Link>
  );
};

export default EnhancedLink;
