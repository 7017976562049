import React from 'react';

const SquaredInstagramIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={56}
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M33 18H23C20.2386 18 18 20.2386 18 23V33C18 35.7614 20.2386 38 23 38H33C35.7614 38 38 35.7614 38 33V23C38 20.2386 35.7614 18 33 18Z"
      stroke="#F2F2F2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.9997 27.37C32.1231 28.2022 31.981 29.0522 31.5935 29.799C31.206 30.5458 30.5929 31.1514 29.8413 31.5297C29.0898 31.9079 28.2382 32.0396 27.4075 31.9059C26.5768 31.7723 25.8095 31.3801 25.2145 30.7852C24.6196 30.1902 24.2274 29.4229 24.0938 28.5922C23.9601 27.7615 24.0918 26.9099 24.47 26.1584C24.8483 25.4069 25.4539 24.7937 26.2007 24.4062C26.9475 24.0187 27.7975 23.8766 28.6297 24C29.4786 24.1259 30.2646 24.5215 30.8714 25.1283C31.4782 25.7351 31.8738 26.5211 31.9997 27.37Z"
      stroke="#F2F2F2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.5 22.5H33.51"
      stroke="#F2F2F2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="0.5" y="0.5" width={55} height={55} stroke="#F2F2F2" />
  </svg>
);

export default SquaredInstagramIcon;
