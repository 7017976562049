import '../styles/boilerplate.scss';

import React, { useEffect } from 'react';
import Rellax from 'rellax';

import {
  PARALLAX_CONSUMER_CLASS_NAME,
  PARALLAX_DEFAULT_SPEED,
} from '../config';
import { useConfigData } from '../data/hooks/use-config-data';
import { win } from '../fragments';
import aos from '../fragments/aos';
import CookiesDialog from './cookies-dialog';
import Footer from './footer';
import Navigation from './navigation';
import PageSeo from './page-seo';
import ScrollTo from './scroll-to';

interface BoilerplateProps {
  pageId: string;
  seoId: string;
}

const Boilerplate: React.FC<BoilerplateProps> = ({
  children,
  seoId,
  pageId,
}) => {
  const config = useConfigData().fallback.data;

  useEffect(() => {
    initializeTheme();
    aos();
  }, []);

  useEffect(() => {
    const parallaxInstance = new Rellax(`.${PARALLAX_CONSUMER_CLASS_NAME}`, {
      speed: PARALLAX_DEFAULT_SPEED,
    });

    return () => {
      if (typeof parallaxInstance?.refresh === 'function') {
        parallaxInstance?.refresh();
      }
    };
  }, []);

  const initializeTheme = async () => {
    if (win) {
      const WebFontLoader = await import('webfontloader');

      await WebFontLoader.load({
        google: {
          families: config.theme.fontFamilies,
        },
      });

      const rootElement = win.document.querySelector('html');
      const cssVariablesDefinition = [
        {
          name: '--theme-primary',
          value: config.theme.primaryColor,
        },
        {
          name: '--theme-secondary',
          value: config.theme.secondaryColor,
        },
        {
          name: '--theme-on-secondary',
          value: config.theme.onSecondaryColor,
        },
        {
          name: '--theme-grey-color',
          value: config.theme.greyColor,
        },
        {
          name: '--theme-text-color',
          value: config.theme.textColor,
        },
        {
          name: '--theme-font-primary',
          value: config.theme.fontFamilies[0].split(':')[0],
        },
      ];

      cssVariablesDefinition.forEach(({ name, value }) =>
        rootElement.style.setProperty(name, value),
      );
    }
  };

  return (
    <div className="boilerplate">
      <PageSeo id={seoId} />
      <Navigation />
      <main id={pageId}>{children}</main>
      <CookiesDialog />
      <ScrollTo />
      <Footer />
    </div>
  );
};

export default Boilerplate;
