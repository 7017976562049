import '../styles/newsletter-form.scss';

import { Button, Form, Input, notification } from 'antd';
import React, { useState } from 'react';

import { useNewsletterSectionData } from '../data';
import { post } from '../rest';
import { Box } from './layout';

const NewsletterForm: React.FC = () => {
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const { data: newsletterSectionData } = useNewsletterSectionData().fallback;

  const [form] = Form.useForm();

  const handleFormSubmit = async fieldsValues => {
    await form?.validateFields();

    setFormLoading(true);

    try {
      await post(newsletterSectionData.newsletterActionUrl, fieldsValues);
      setFormLoading(false);
      notification.success({
        message: 'Cześć! Wysłaliśmy Ci powitalnego e-maila.',
      });
    } catch {
      setFormLoading(false);
      notification.error({
        message: 'Przepraszamy, wystąpił błąd. Prosimy spróbować ponownie.',
      });
    }
  };

  return (
    <Form className="newsletter-form" onFinish={handleFormSubmit}>
      <Input size="large" name="email" placeholder="TWÓJ ADRES E-MAIL" />
      <Box>
        <Button
          type="link"
          htmlType="submit"
          size="large"
          loading={formLoading}
          disabled={formLoading}
          aria-label="ZAPISZ SIĘ"
        >
          ZAPISZ SIĘ
        </Button>
      </Box>
    </Form>
  );
};

export default NewsletterForm;
