import React from 'react';

const SquaredFacebookIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={56}
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M34 18H31C29.6739 18 28.4021 18.5268 27.4645 19.4645C26.5268 20.4021 26 21.6739 26 23V26H23V30H26V38H30V30H33L34 26H30V23C30 22.7348 30.1054 22.4804 30.2929 22.2929C30.4804 22.1054 30.7348 22 31 22H34V18Z"
      stroke="#F2F2F2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="0.5" y="0.5" width={55} height={55} stroke="#F2F2F2" />
  </svg>
);

export default SquaredFacebookIcon;
