import '../../styles/section-heading.scss';

import { Typography } from 'antd';
import React from 'react';

import { ContentfulRichText } from '../../types';
import { classNameMap } from '../../utils';
import { Box } from '../layout';
import { richTextToReactComponents } from '../utils';

interface SectionHeadingProps {
  title?: string;
  subtitle?: string;
  description?: ContentfulRichText | string;
  align?: 'start' | 'center' | 'end';
  autoWidth?: boolean;
  descriptionExtra?: React.ReactNode;
}

const SectionHeading: React.FC<SectionHeadingProps> = ({
  title,
  description,
  subtitle,
  autoWidth = false,
  align = 'center',
  descriptionExtra,
}) => (
  <Box
    justify={align}
    direction="column"
    className={classNameMap({
      'section-heading': true,
      [`section-heading--${align}`]: true,
      [`section-heading--auto-width`]: autoWidth,
    })}
  >
    {subtitle && (
      <Typography.Paragraph className="section-heading__subtitle">
        {subtitle}
      </Typography.Paragraph>
    )}

    {title && (
      <Typography.Title level={3} className="section-heading__title">
        {title}
      </Typography.Title>
    )}

    {typeof description === 'string' && (
      <Box className="section-heading__description">
        <Typography.Paragraph>{description}</Typography.Paragraph>
        <Box justify="end">{descriptionExtra}</Box>
      </Box>
    )}

    {typeof description !== 'string' && description?.raw && (
      <Box className="section-heading__description">
        {richTextToReactComponents(description)}
        <Box justify="end">{descriptionExtra}</Box>
      </Box>
    )}
  </Box>
);

export default SectionHeading;
