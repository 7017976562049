import '../styles/social-medias.scss';

import React from 'react';

import { useConfigData } from '../data';
import SquaredFacebookIcon from './icons/squared-facebook';
import SquaredInstagramIcon from './icons/squared-instagram';
import SquaredLinkedInIcon from './icons/squared-linkedin';
import { Box } from './layout';

const SOCIAL_MEDIA_ICONS = {
  facebook: <SquaredFacebookIcon />,
  linkedin: <SquaredLinkedInIcon />,
  instagram: <SquaredInstagramIcon />,
};

const SocialMedias = () => {
  const { data } = useConfigData().fallback;

  return (
    <Box className="social-medias">
      {data.socialMedias.map((item, index) => (
        <a
          className="social-medias__item"
          key={index}
          href={item.url}
          target="_blank"
          aria-label={item.name}
          rel="noopener noreferrer"
        >
          {SOCIAL_MEDIA_ICONS[item.name.toLowerCase()]}
        </a>
      ))}
    </Box>
  );
};

export default SocialMedias;
