import { createContext } from 'react';

import { StaticPageId } from '../data';

export interface PageLocalContextInterface {
  id: string;
  pageId: StaticPageId;
}

export const PageLocalContext = createContext<
  Partial<PageLocalContextInterface>
>({});
