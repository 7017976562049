import '../styles/navigation.scss';

import { Drawer } from 'antd';
import { Link } from 'gatsby';
import { chunk } from 'lodash-es';
import React, { useEffect, useRef, useState } from 'react';

import { useConfigData } from '../data';
import { win } from '../fragments/window';
import EnhancedLink from './common/enhanced-link';
import CloseIcon from './icons/close';
import HamburgerIcon from './icons/hamburger';
import PhoneIcon from './icons/phone';
import Image from './image';
import { Box, Col, Container, Row } from './layout';

const Navigation: React.FC = () => {
  const [isDrawerOpened, setDrawerOpened] = useState<boolean>(false);
  const config = useConfigData().fallback.data;
  const headerElementRef = useRef<HTMLDivElement>();

  const STATIC_NAV_ITEMS = [
    {
      component: (
        <a
          href={`tel:${config.phoneNumber}`}
          className="contact-telephone"
          aria-label="contact telephone"
        >
          <PhoneIcon />
          {config.phoneNumber}
        </a>
      ),
    },
  ];

  const navigationItems = [...config.mainNavigation, ...STATIC_NAV_ITEMS];

  const halfNavigation = navigationItems.length / 2;
  const [navPartOne, navPartTwo] = chunk(config.mainNavigation, halfNavigation);

  useEffect(() => {
    if (headerElementRef.current && win) {
      win.document.addEventListener('scroll', () => {
        if (win.scrollY > 950) {
          headerElementRef.current?.classList.add('header-sticky');
        }

        if (win.scrollY < 50) {
          headerElementRef.current?.classList.remove('header-sticky');
        }
      });
    }
  }, [headerElementRef?.current]);

  return (
    <header ref={headerElementRef}>
      <Container>
        <Row align="middle" justify="space-between" style={{ height: '100%' }}>
          <Col span={{ xl: 9 }} hidden={{ sm: true, xl: false }}>
            <Box className="navigation-desktop">
              {navPartOne.map((item, index) => (
                <EnhancedLink key={index} item={item} />
              ))}
            </Box>
          </Col>

          <Col span={{ sm: 8 }} hidden={{ sm: false, xl: true }}>
            <Box align="middle">
              <a
                href={`tel:${config.phoneNumber}`}
                className="contact-telephone"
                aria-label="contact telephone"
              >
                <PhoneIcon />
              </a>
            </Box>
          </Col>

          <Col
            span={{ sm: 8, md: 6, xl: 6 }}
            style={{ textAlign: 'center' }}
            center
          >
            <Link
              style={{ display: 'block', lineHeight: '0' }}
              to="/"
              aria-label="company logo"
            >
              <Image id={config.logo.id} />
            </Link>
          </Col>

          <Col span={{ xl: 9 }} hidden={{ sm: true, xl: false }}>
            <Box className="navigation-desktop">
              {navPartTwo.map((item, index) => (
                <EnhancedLink key={index} item={item} />
              ))}
              {STATIC_NAV_ITEMS.map((item, index) => (
                <React.Fragment key={index}>{item.component}</React.Fragment>
              ))}
            </Box>
          </Col>

          <Col hidden={{ sm: false, xl: true }} span={{ sm: 8, md: 6 }}>
            <Box align="middle" fullHeight justify="end">
              <div
                className="hamburger-btn btn"
                tab-index="0"
                onClick={() => setDrawerOpened(true)}
              >
                <HamburgerIcon />
              </div>
            </Box>
          </Col>
        </Row>
      </Container>

      <Box className="navigation-mobile" hidden={{ sm: false, xl: true }}>
        <Drawer
          placement="right"
          closable={false}
          width="100%"
          visible={isDrawerOpened}
        >
          <div className="drawer-head">
            <div className="drawer-head__company-logo">
              <Link to="/" aria-label="company logo">
                <Image id={config.logo.id} />
              </Link>
            </div>

            <div
              className="close-btn btn"
              tab-index="0"
              onClick={() => setDrawerOpened(false)}
            >
              <CloseIcon />
            </div>
          </div>

          <div className="drawer-body">
            {config.mainNavigation.map((item, index) => (
              <EnhancedLink key={index} item={item} />
            ))}
          </div>
        </Drawer>
      </Box>
    </header>
  );
};

export default Navigation;
