import React from 'react';

const SquaredLinkedInIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={56}
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M32 24C33.5913 24 35.1174 24.6321 36.2426 25.7574C37.3679 26.8826 38 28.4087 38 30V37H34V30C34 29.4696 33.7893 28.9609 33.4142 28.5858C33.0391 28.2107 32.5304 28 32 28C31.4696 28 30.9609 28.2107 30.5858 28.5858C30.2107 28.9609 30 29.4696 30 30V37H26V30C26 28.4087 26.6321 26.8826 27.7574 25.7574C28.8826 24.6321 30.4087 24 32 24V24Z"
      stroke="#F2F2F2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 25H18V37H22V25Z"
      stroke="#F2F2F2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 22C21.1046 22 22 21.1046 22 20C22 18.8954 21.1046 18 20 18C18.8954 18 18 18.8954 18 20C18 21.1046 18.8954 22 20 22Z"
      stroke="#F2F2F2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="0.5" y="0.5" width={55} height={55} stroke="#F2F2F2" />
  </svg>
);

export default SquaredLinkedInIcon;
