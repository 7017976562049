import '../styles/scroll-to.scss';

import { BackTop } from 'antd';
import React from 'react';

import ArrowRightIcon from './icons/arrow-right';

const ScrollTo: React.FC = () => (
  <BackTop>
    <div className="scroll-to-content">
      <ArrowRightIcon />
    </div>
  </BackTop>
);

export default ScrollTo;
