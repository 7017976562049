import '../styles/footer.scss';

import { Collapse, Typography } from 'antd';
import { Link } from 'gatsby';
import React, { useContext, useRef } from 'react';

import { PageLocalContext } from '../context/page';
import {
  StaticPageId,
  useConfigData,
  useEuropeanFundsSectionData,
  useNewsletterSectionData,
} from '../data';
import EnhancedLink from './common/enhanced-link';
import EmailIcon from './icons/email';
import PhoneIcon from './icons/phone';
import Image from './image';
import {
  Box,
  Col,
  Container,
  LayoutBreakpoint,
  Row,
  Section,
  useBreakpoint,
} from './layout';
import NewsletterForm from './newsletter-form';
import SocialMedias from './social-medias';
import { richTextToReactComponents } from './utils';

const Footer: React.FC = () => {
  const { data: configData } = useConfigData().fallback;
  const { data: efSectionData } = useEuropeanFundsSectionData().fallback;
  const { data: newsletterData } = useNewsletterSectionData().fallback;
  const efSectionPanelRef = useRef<HTMLDivElement>();
  const pageLocalContext = useContext(PageLocalContext);
  const isMd = useBreakpoint(LayoutBreakpoint.MD);

  return (
    <>
      <Section>
        <Container>
          <Row>
            <Col span={{ lg: 6, sm: 24 }} center={isMd}>
              <Box className="footer footer__no-margin">
                <Link to="/">
                  <Typography.Paragraph className="footer__title">
                    {configData.companyName}
                  </Typography.Paragraph>
                </Link>
                <div className="footer__navigation">
                  {configData.mainNavigation.map((item, index) => (
                    <EnhancedLink key={index} item={item} />
                  ))}
                </div>
              </Box>
            </Col>

            <Col span={{ lg: 9, sm: 24 }} center={isMd}>
              <Box className="footer">
                <Box>
                  <Typography.Paragraph className="footer__about">
                    {configData.translations?.owner}
                  </Typography.Paragraph>

                  <Typography.Paragraph className="footer__description">
                    <div>{configData.translations?.positionTitle}</div>
                    <div>{configData.translations?.positionSubtitle}</div>
                  </Typography.Paragraph>
                </Box>

                <a
                  className="footer__phone"
                  href={`tel:${configData.phoneNumber}`}
                >
                  <PhoneIcon />
                  {configData.phoneNumber}
                </a>

                <a
                  className="footer__email"
                  href={`mailto:${configData.email}`}
                >
                  <EmailIcon />
                  {configData.email}
                </a>

                <Box>
                  <Typography.Paragraph className="footer__title">
                    OBSERWUJ NA
                  </Typography.Paragraph>
                  <SocialMedias />
                </Box>
              </Box>
            </Col>

            <Col span={{ lg: 9, sm: 24 }} center={isMd}>
              <Box className="footer">
                <Typography.Paragraph className="footer__beautyletter-title">
                  {newsletterData.title}
                </Typography.Paragraph>

                <Typography.Paragraph className="footer__beautyletter-subtitle">
                  {newsletterData.subtitle}
                </Typography.Paragraph>

                <NewsletterForm />

                <Typography.Paragraph className="footer__beautyletter-description">
                  {newsletterData.extra}
                </Typography.Paragraph>

                <Typography.Paragraph className="footer__beautyletter-privacy">
                  {configData.footerNavigation.map((item, index) => (
                    <EnhancedLink key={index} item={item} />
                  ))}
                </Typography.Paragraph>
              </Box>
            </Col>
          </Row>
        </Container>
      </Section>

      {pageLocalContext?.pageId === StaticPageId.Contact && (
        <Box className="european-funds-section">
          <Collapse
            ghost
            onChange={() =>
              setTimeout(
                () => efSectionPanelRef?.current?.scrollIntoView(),
                500,
              )
            }
          >
            <Collapse.Panel
              showArrow={false}
              header={
                <Box justify="center" align="middle">
                  {efSectionData.images.map((image, index) => (
                    <Image id={image.id} key={index} />
                  ))}
                </Box>
              }
              key="1"
            >
              <div ref={efSectionPanelRef}>
                <Container>
                  {richTextToReactComponents(efSectionData.content)}
                </Container>
              </div>
            </Collapse.Panel>
          </Collapse>
        </Box>
      )}
    </>
  );
};

export default Footer;
